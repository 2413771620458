import React from "react";

import "normalize.css";
import "./src/styles/global.css";
import "./src/styles/scrollbar.css";
import "./src/styles/typography.css";
import "@fontsource/noto-serif-sc";
import "@fontsource/noto-sans-sc";
// import "@fontsource/jetbrains-mono";

export const wrapRootElement = ({ element }) => {
  return <>{element}</>;
};
